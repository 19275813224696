.request-delivery-modal {
    .modal-header, .modal-footer {
        padding-left: 40px;
        padding-right: 40px;
    }

    .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .items-container {
        min-height: 500px;
        padding: 40px;
        overflow-y: scroll;
    }

    .item {
        margin-bottom: 40px;
    }

    .form-select:disabled {
        background: $white;
        border-color: transparent;
        min-height: 40px;
    }

    .form-floating {
        #quantity {
            min-width: 100px;
            border-color: transparent;
        }
        #units + label {
            padding-left: 10px;
        }
    }

    .remaining-items {
        .remaining-items--label {
            margin: 0;
            font-size: 12px;
        }
    }

    .delivery-details {
        padding: 20px 40px 20px;
        box-shadow: 0px -4px 10px 0px rgba(137, 137, 147, 0.15);
        .datepicker-cell {
            border-radius: 4px;
            border: 1px solid $gray-base;

            .input-group {
                padding: 0 10px;
            }

            .form-control {
                height: 40px;
            }
        }
    }

    .delivery-address-button {
        border: 1px solid $border-color;
        padding: 8px 10px;
        width: 100%;
    }
}