.projectMaterialOverview {
  margin-top: 40px;
  min-height: 40vh;
  position: relative;

  .table-wrapper {
    position: relative;
    min-height: 40vh;
  }

  thead {
    th {
      color: rgba($black, 0.8);
      font-family: $spartan-font;
      font-weight: 600;
      font-size: $font-size-sm;
    }
  }
  tbody {
    th,
    td {
      font-weight: 400;
      font-size: $font-size-sm;
    }

    .partners {
      .has-link,
      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.projectMaterialOverview--top {
  margin-bottom: 30px;
}

.projectMaterialOverview--title {
  font-weight: 500;
  font-size: $h2-font-size;
}

.projectMaterialOverview--export {
  margin-right: 10px;
}

.order-arrow {
  cursor: pointer;
  display: block;
  padding: 5px;
  &.ascending,
  &.descending {
    background-color: #f7f7fc;
  }
  &.descending {
    svg {
      transform: rotate(180deg);
    }
  }
}

.tag {
  display: inline-block;
  padding: 3px 27px;
  border-radius: 16px;
  background-color: rgba($yellow, 0.6);
  font-weight: 600;
  &.approved {
    background-color: rgba($green, 0.2);
  }
  &.invoiced_paid {
    background-color: rgba($green, 0.6);
  }
  &.delivered {
    background-color: rgba($violet, 0.2);
  }
  &.invoiced_overdue {
    background-color: rgba($error-red, 0.6);
  }
}

.table-filter,
.table-filter-input {
  margin-top: 15px;
  position: relative;
}

.table-filter {
  .dropdown-wrapper {
    display: flex;
    .selected-item {
      width: 82%;
    }
  }
}

.table-filter-input {
  input {
    padding-right: 38px;
  }
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.create-empty-basket-modal {
  .modal-body {
    padding: 24px 40px;
  }

  .form-select {
    padding-left: 17px;
    height: 48px;
  }
}

.new-project-modal {
  .modal-body {
    padding: 0px 40px !important;
  }
}

.create-empty-basket-modal-title {
  font-weight: 400;
}

.modal-dropdown {
  display: block;
  max-width: 395px;
  width: 100%;
  .dropdown-toggle {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 0;
    border: 1px solid #e4e6f2 !important;
    box-shadow: none !important;
    background-color: $white;
    color: $gray-base;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
  }

  .dropdown-menu.show {
    height: 200px;
    overflow-y: scroll;
    width: 100%;
  }
}

.modal-dropdown-lg {
  position: relative;
  display: block;
  width: 100%;
  .dropdown-toggle {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 0;
    border: 1px solid #e4e6f2 !important;
    box-shadow: none !important;
    background-color: $white;
    color: $gray-base;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
  }

  .search-input-wrapper {
    input {
      width: 100%;
      padding: 12px;
    }
  }

  .dropdown-menu.show {
    height: 200px;
    overflow-y: scroll;
    width: 100%;
    padding-top: 0;
  }
}
.text-underline {
  text-decoration: underline;
  color: #664bf5;
  cursor: pointer;
}
