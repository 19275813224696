.dropdown-wrapper {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba($gray-base, 0.3);
  background-color: $white;
  color: $black;
  height: 48px;

  &.is-invalid {
    border-color: $danger-base;
    .dropdown-icon {
      border-color: $danger-base;
    }
  }

  .dropdown-icon {
    border-left: 1px solid rgba($gray-base, 0.3);
  }

  &.show {
    .dropdown-icon {
      svg {
        rotate: 180deg;
      }
    }
  }

  .selected-item {
    padding: 12px;
  }
}

.dropdown-list {
  position: absolute;
  left: -1px;
  right: -1px;
  top: 97%;
  z-index: 9;
  padding: 10px 15px 0 15px;
  border: 1px solid rgba($color: $gray-base, $alpha: 0.3);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: $white;
  max-height: 90px;
  overflow-y: auto;
  div {
    cursor: pointer;
    margin-bottom: 10px;
  }
}
