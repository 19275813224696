.product-card {
  position: relative;
  padding: 24px;
  border-top: 1px solid $border-color;
  &:hover {
    background: #f2f2f8;
  }

  img {
    cursor: pointer;
    max-height: 25vh;
    object-fit: contain;
  }

  .row {
    &:hover {
      cursor: pointer;
    }
  }
}

.product-favorite {
  margin-bottom: 12px;
  text-align: right;
  svg {
    margin-right: 10px;
  }
  span {
    color: rgba($black, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}

.product-card--title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.48;
}

.product-card--attribute {
  margin-right: 41px;
  margin-top: 8px;
  color: rgba($black, 0.6);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

.product-card--status {
  display: flex;
  align-items: center;
}

.product-card--status-bullet {
  display: inline-block;
  margin-right: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  &.status-available {
    background-color: $success-base;
  }
}

.product-card--price {
  margin-top: 28px;

  p {
    margin-bottom: 10px;
    margin-left: 20px;
    color: rgba($black, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-indent: -20px;
    svg {
      margin-right: 6px;
    }
  }

  .price {
    color: $black;
    strong {
      margin: 0 9px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }
  }
}

.product-card--actions {
  margin-top: 24px;
}

.product-dropdown {
  width: 100%;
  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 14px;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid $border-color !important;
    border-radius: 0;
    color: rgba($black, 0.6);
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    &:after {
      margin-left: 0 !important;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      border: 0;
      border-top: 1px solid $border-color;
      border-left: 1px solid $border-color;
      transform: rotate(225deg) translateY(20%);
    }
  }
}

.save-item {
  padding: 10px 24px;
  svg {
    margin-left: 10px;
  }
}

.product-modal {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
  max-width: 770px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 35%);
  background: $white;
  z-index: 9999;

  .container {
    margin-top: 0 !important;
  }

  .product-favorite {
    margin-bottom: 19px;
    span {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }

  .product-card--price {
    .price {
      color: $violet;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.tabs-wrapper {
  margin-top: 15px;

  .nav-item {
    flex-grow: 1;
  }

  .nav-tabs {
    border-top: 1px solid $border-color;
    .nav-link {
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 0;
      border-top: 0;
      border-color: $border-color;
      background-color: #f7f7fc;
      color: rgba($black, 0.6);
      font-size: $font-size-base;
      line-height: 19px;
      text-align: center;
      cursor: pointer;

      &.active {
        border-bottom-color: $white;
        background-color: $white;
        font-weight: 700;
      }
    }
  }

  .tab-content {
    padding-top: 32px;
    background-color: $white;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .see-all-product-details {
      padding: 35px 35px 31px 0;
    }

    .table {
      margin-bottom: 0;
      td {
        height: 48px;
        padding-left: 35px;
        // text-transform: capitalize;
        color: rgba($primary-base, 0.6);
        + td {
          padding-right: 35px;
          color: $primary-base;
          font-weight: 600;
        }
      }
    }

    .short-description {
      padding: 20px 35px 0;
      p {
        margin: 0;
      }
    }
  }
}

.tab-visible-toggler {
  margin-bottom: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 2px;
  color: rgba($black, 0.6);
  font-size: $font-size-base;

  &:hover {
    background-color: $white;
  }

  &.is-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.login-info-message {
  padding: 10px 16px;
  background: #F7F7FC;
  border-radius: 8px;
  svg {
    margin-right: 8px;
  }
  p {
    color: #404089;
    font-size: 12px;
  }
  &:hover {
    cursor: default !important;
  }
  span {
    &:hover {
      cursor: pointer;
    }
  }
}