.categorie-row {
    margin-bottom: 27px;
    p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
}

.success-image-wrap {
    pointer-events: none;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin-right: 5px;
    border: solid $black 1px;
}
  
.success-black {
    background-color: $primary-base;
    color: $white;
    &:hover {
        background-color: $primary-base;
        color: $white;
    }
    &:focus {
        background-color: $primary-base;
        color: $white;
        outline: none !important
    }
  }
  
.success-white {
    background-color: $white;
    color: $primary-base;
    &:focus {
        background-color: transparent;
    }
    &:hover {
        background-color: transparent;
        color: $primary-base;

        .success-image-wrap {
            background-color: rgba($secondary-base, 0.5)
        }
    }
}
  
.success-icon {
    border-radius: 50%;
}

.left-button {
    &:focus {
        background-color: $primary-base;
        color: $white;
    }
}
  
.left-button {
    padding-left: 32px;
    padding-right: 32px;
    min-width: 150px;
    border-radius: 0;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    box-shadow: $box-shadow !important;
}
  
.right-button {
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 0;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    min-width: 150px;
    box-shadow: $box-shadow !important;
}

.landing-page--content-side-top, .landing-page--content-side-bottom {
    margin-bottom: 20px;
    padding: 23px 36px;
    background: $white;
    box-shadow: $box-shadow;
    border-radius: 9px;
    @include media-breakpoint-up(xl) {
        padding-top: 34px;
        padding-bottom: 34px;
    }

    hr {
        margin: 36px 0 0 0;
    }
}

.landing-page--content-side-bottom {
    margin: 0;
}

.landing--page-content-side-title {
    margin-bottom: 1rem;
    @include media-breakpoint-up(xl) {
        margin-bottom: 0;
    }
}

.guide-section, .benefits-text-image-section {
    padding: 23px 16px;
    @include media-breakpoint-up(xl) {
        padding: 34px 36px;
    }
}

.guide-section {
    h5 {
        margin-bottom: 4px;
    }
    p {
        margin-bottom: 40px;
    }
}

.benefits-button {
    margin-top: 33px;

    .btn-ouline-primary {
        padding: 10px 32px;
    }
}

.handler {
    display: flex;
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    div {
        font-size: 18px;
    }
}