.error {
  background-color: #ffb6c1 !important;
}

.ag-popup {
  height: 0 !important;
}

.jitsi-draggable {
  position: fixed;
  top: 100px;
  z-index: 9999;
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.spinner-container {
  position: relative;
}

.btable {
  padding: 0rem 0rem 0rem 0rem !important;
}

.category-description {
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.48;
    color: $black;
  }
  h2 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }
  h3 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(26, 23, 27, 0.7);
  margin-bottom: 20px;
  padding: 0 24px;
}
.see-more,
.see-less {
  color: $violet;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px;
}
.see-more-btn{
  margin-top: -20px;
  margin-bottom: 15px;
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
}
.link-button {
  --cui-btn-padding-x: 0;
}

.drawer {
  &__footer {
    z-index: 20;
    position: sticky;
    border-top: 1px solid var(--cui-gray-200);
    background: white;
    bottom: 0;
    margin: 0 -1rem;
    padding-inline: 20px;
  }

  &__footer-btn {
    margin: 1rem 1rem 1rem 1rem;
  }

  &__footer-back-btn,
  &__footer-next-btn {
    margin: 1rem 1rem 1rem 1rem;
  }

  &__offcanvas-sticky {
    position: sticky;
    --cui-offcanvas-padding-y: 0rem;
  }
}

.deactivate {
  &__icn {
    filter: invert(14%) sepia(97%) saturate(7053%) hue-rotate(357deg) brightness(89%) contrast(129%);
  }

  &__label {
    color: $danger-base;
  }
}

.stepper {
  &__sticky {
    margin: 0 -1rem 1rem;
    position: sticky;
    top: 0;
    z-index: 20;
    background: white;
    border-bottom: 1px solid var(--cui-gray-200);
  }

  &__form {
    width: 100%;
    padding-inline: 20px;
  }

  &__form-height {
    min-height: 100%;
  }
}

.nav-underline .nav-link,
.ag-header-cell-text,
.MuiStepLabel-label {
  font-family: $spartan-font !important;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-base;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray-base;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-theme-alpine .ag-cell-value {
  line-height: 20px !important;
  word-break: normal;
  /* prevent words from breaking */
  padding-top: 5px;
  /* space top */
  padding-bottom: 5px;
  /* space bottom */
}

.ag-cell {
  display: flex;
  align-items: center;
}

.offcanvas-title {
  padding: 0 20px;
}

.ag-cell-wrapper {
  width: 100%;
}

.checkbox {
  accent-color: var(--cui-secondary);
}

.custom-radio {
  margin-bottom: 5px;
}

.custom-label {
  color: black;
  margin-left: 5px;
  font-size: 15px;
  cursor: pointer;
}

.tooltip {
  --cui-tooltip-color: black;
  --cui-tooltip-bg: white;
  --cui-tooltip-opacity: 100;
  --cui-tooltip-max-width: 300px;
}

.success {
  &__icn {
    filter: invert(36%) sepia(52%) saturate(7237%) hue-rotate(135deg) brightness(86%) contrast(98%);
  }
}

.fail {
  &__icn {
    filter: invert(11%) sepia(64%) saturate(6130%) hue-rotate(347deg) brightness(87%) contrast(117%);
  }
}

.breadcrumb-link {
  cursor: pointer;
}

.mr {
  margin-right: 10px;
}

.orientation-fullscreen-overlay {
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  .orientation-overlay-text {
    height: 100%;
  }
}
