@import 'material/material';
@import 'material-negotiation/material';
@import 'material-delivered-tab';
@import 'material-invoiced-tab';

.material-view--section {
  background-color: $white;
}

.material-view--head {
  margin-bottom: 16px;

  .material-view--head-wrapper {
    margin: 0 12px;
    padding: 20px;
    background-color: $white;
  }

  .material-view--contacts-wrapper {
    margin: 0 0px;
    padding: 0px;
    background-color: $white;
  }

  .billing-address-field,
  .phone-number-field {
    margin-top: 16px;
    @include media-breakpoint-up(xxl) {
      margin-top: 0;
    }
  }

  .on-site-contact-field,
  .bc-delivery-field,
  .project-number-field {
    margin-top: 16px;
    @include media-breakpoint-up(xxl) {
      margin-top: 0;
    }
  }

  .project-number-field,
  .on-site-contact-field,
  .bc-delivery-field {
    @include media-breakpoint-up(md) {
      margin-right: 24px;
    }
  }

  .form-control,
  .form-select {
    min-width: 268px;
  }
}

.material-view--title-details-section {
  margin-top: 24px;
  @include media-breakpoint-up(xxl) {
    margin-top: 40px;
  }
}

.material-view--title {
  margin: 0;
  font-size: $h4-font-size;
  font-weight: 500;
}

.show-hide-top-details {
  color: $violet;
  cursor: pointer;
  text-decoration: underline;
}

.material-view--search-filter {
  .row {
    padding: 17px 0;
  }

  .icon-dropdown {
    .dropdown-toggle {
      padding: 10px 28px;

      p {
        font-weight: 500;
        font-size: 24px;
      }
    }
  }
  .filter-section-search {
    min-width: 220px;
  }
}

.buy-now-button {
  background-color: $yellow !important;
  color: $black !important;
}

.material-view--content {
  margin-top: 20px;

  .no-material {
    margin: 100px 0;
    @include media-breakpoint-up(xxl) {
      margin-bottom: 0;
    }

    .navigate {
      cursor: pointer;
    }
  }
}

.select-all-checkbox {
  margin-bottom: 12px;

  .form-check {
    margin: 0;
  }

  span {
    margin-left: 5px;
    color: rgba($black, 0.6);
    font-size: 12px;
    font-weight: 400;
  }
}

.material-view-page {
  min-height: 80vh;
  position: relative;

  .nav-tabs {
    background-color: $white;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    min-height: 52px;
    @include media-breakpoint-up(xl) {
      overflow: hidden;
    }

    .nav-item {
      flex-shrink: 0;
    }

    .nav-link {
      padding: 12px 16px;
      background-color: $white;
      border-radius: 0;
      border: none;

      &:hover {
        font-weight: 600;
      }

      &.active {
        border: none;
        border-bottom: 4px solid $yellow;
        color: $black;
        font-weight: 600;
      }
    }
  }
}

.material-group {
  padding: 19px 0 0 0;
  background-color: $white;
  .line-separator {
    margin-bottom: 0;
  }
}

.material-group-delivery-date {
  margin-bottom: 24px;
}

.material-group-title {
  padding: 0 25px;
}

.product-changes-wrap {
  background-color: #ebebf2;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 6px;
}

.invalid {
  border: 2px solid red;
}
.box-shadow {
  box-shadow: 0 -8px 8px -8px rgba(0, 0, 0, 0.1);
}

.modal-body {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.bg-gray {
  background-color: '#F5F5F5';
}
