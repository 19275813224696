.navbar {
  padding: 0;
  z-index: 99;

  @include media-breakpoint-up(xxl) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.navbar-wrapper {
    padding: 12px;
    position: sticky;
    top: 0;
    z-index: 99;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.secondary-nav {
  position: sticky;
  z-index: 98;
  background-color: $body-bg;
  &.sticky-secondary-nav {
    top: 77px;
  }
}

.navbar-collapse {
  @media (min-width: 1440px) {
    padding: 0;
    height: auto;
    max-width: 100%;
  }
}

.navbar-link {
  text-decoration: none;
}

.nav-item {
  &.show {
    .choice-button {
      background: $secondary-base;

      svg {
        path {
          fill: $primary-base;
        }
      }
    }
  }
}

.search-input-field-wrap {
  position: relative;
  flex: 0 0 510px;
  width: 100%;
}

.search-input {
  padding: 14.5px 14px 14.5px 40px;
  border: none;
  font-size: $font-size-sm;
}

.search-input-field-image {
  position: absolute;
  left: 0;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 16px;
}

.search-input-field-spinner {
  position: absolute;
  left: 5px;
  top: 17px;
}

.vendor-product-dropdown-wrapper {
  width: 210px;
  flex: 0 0 138px;

  .dropdown-wrapper {
    height: 48px;
    padding-left: 16px;
    justify-content: space-between;

    &.show,
    &:focus {
      outline: 4px solid rgba($light-violet, 0.6);
    }

    .selected-item {
      width: auto;
      padding: 0;
      border-right: none;
      text-align: left;
      font-weight: 400;
      font-size: $font-size-sm;
      line-height: 16px;
      letter-spacing: 0.015em;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.search-button {
  border: none;
  flex: 0 0 56px;
  width: 56px;
  height: 48px;
  margin-left: 0;
  padding: 0;
  background-color: $yellow;
  border-radius: 0;

  &:hover,
  &:active {
    background-color: $secondary-base !important;
  }
}

.choice-button {
  cursor: pointer;
}

.favorite-icon {
  &:hover {
    svg {
      path {
        stroke: $yellow;
      }
    }
  }
}

.login-icon {
  &:hover {
    .user-loggedIn-icon {
      color: $black;
      background-color: $yellow;
    }
  }
}

.user-loggedIn-wrapper {
  &:hover {
    .user-loggedIn-icon {
      background-color: $yellow;
      color: $black;
    }
  }
}

.user-loggedIn-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $violet;
  color: $white;
  font-size: 15px;

  &.is-clicked {
    background-color: $yellow;
    color: $black;
  }
}

.choice-dropdown {
  padding: 25px;
  width: 367px;
}

.choice-dropdown-login {
  span {
    cursor: pointer;
  }
}

.dropdown-content {
  font-size: 15px;
}

.info-icon-popover {
  position: relative;
  margin-right: 15px;
}

.login-button-link {
  color: $primary-base;
  cursor: pointer;
  text-decoration: underline;
}

.login-popover-headline {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
}

.shallowsearchItem {
  cursor: pointer;
}

.shallow-search-item-categories {
  margin-bottom: 12px;

  span {
    white-space: break-spaces;
  }
}
