.loggedin-button {
    position: relative;
}

.user-popover {
    opacity: 0;
    display: none;
    width: 563px;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: $white;
    box-shadow: $box-shadow;
    transition: opacity 0.3s ease-in-out;
    &.is-opened {
        opacity: 1;
        display: block;
    }
    .copy-icon {
        margin-left: 8px;
        cursor: pointer;
        svg path {
            fill: transparent !important;
        }
    }
}

.user-popover--head {
    padding: 14px 21px 21px 21px;
    background-color: #482FB1;

    .given-name, .family-name {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
    }

    .given-name {
        margin-right: 4px;
    }

    .user--email {
        margin: 0;
        font-size: $font-size-sm;
        font-weight: 400;
    }

    .user-loggedIn-icon {
        margin-right: 8px;
        background-color: rgba($white, 0.2) !important;
        color: $white !important;
    }
}

.user--role {
    display: inline-block;
    padding: 8px 14px;
    border-radius: 22px;
    background-color: rgba($white, 0.2);
}

.user-popover--content {
    padding: 24px 21px 21px 21px;
}

.user-popover--footer {
    border-top: 1px solid rgba($black, 0.3);
}

.popover-sign-out {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
        cursor: pointer;
    }
    span {
        color: rgba($black, 0.7);
        font-family: $helvetica-font;
        font-weight: 400;
    }
    svg {
        margin-left: 15px;
        path {
            fill: #000;
        }
    }
}

.user-tabs {
    .nav {
        align-items: center;
        justify-content: space-between;
    }

    .nav-item {
        text-align: center;
        flex: 1 0;
    }

    .nav-link {
        padding: 9px;
        border-bottom: 4px solid rgba($black, 0.02);
        color: $black;
        font-family: $spartan-font;
        font-weight: 500;
        &.active {
            border-bottom-color: $yellow;
            color: $black;
            font-weight: 700;
        }
    }

    .tab-content {
        a, span {
            margin-top: 24px;
            display: inline-block;
            color:rgba($black, 0.7);
        }
    }
}